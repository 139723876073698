<template>
    <div v-loading="loading" class="data-element-container">
      <div class="tree-container">
        <p class="tree-top fss">
          <el-input
            v-model="filterText"
            placeholder="输入关键字进行过滤"
          >
          </el-input>
        </p>
        <el-scrollbar class="scrollbar-wrapper-tree">
          <el-tree
            ref="myTree"
            :data="treeData"
            :indent="5"
            node-key="sectionId"
            show-checkbox
            highlight-current
            :expand-on-click-node="false"
            :filter-node-method="filterNode"
            @current-change="changeTree"
          >
            <template #default="{ node, data }">
              <span  class="custom-tree-node">
                <span v-if="data.children && data.children.length > 0 || node.level === 1" class="item-name bold-size">
                  <!-- <svg-icon icon-class="wenjianjia" /> -->
                  <el-icon class="svg-icon"><FolderOpened /></el-icon>
                  {{ node.data.cnName }}
                </span>
                <span v-else class="item-name normal-size">
                  <!-- <svg-icon icon-class="wenjian" /> -->
                  <el-icon class="svg-icon"><DocumentRemove /></el-icon>
                  {{ node.data.cnName }}
                </span>
              </span>
            </template>
          </el-tree>
        </el-scrollbar>
      </div>
      <div class="right-part">
        <div class="btn-row">
          <!--<el-button class="tiffany-btn">生成表格</el-button>-->
          <el-tooltip
            placement="bottom"
            trigger="hover"
          >
            <template #content> 
               <el-button type="primary" @click="download(1)">Excel下载</el-button>
              <el-button type="success" @click="download(2)">Xml下载</el-button>
            </template>
            <el-button type="primary">下载</el-button>
          </el-tooltip>
        </div>
        <div class="element-content">
          <ul class="left-tr-list">
            <li class="tr-item">元数据子集元素</li>
            <li class="tr-item">英文名称</li>
            <li class="tr-item">定义</li>
            <li class="tr-item">数据类型</li>
            <li class="tr-item">数据长度</li>
            <li class="tr-item">值域</li>
            <li class="tr-item">约束</li>
          </ul>
          <ul class="right-tt-list">
            <li class="tt-item">{{ elementData.cnName }}</li>
            <li class="tt-item">{{ elementData.enName }}</li>
            <li class="tt-item">{{ elementData.definition }}</li>
            <li class="tt-item">{{ elementData.dataType }}</li>
            <li class="tt-item">{{ elementData.dataLength }}</li>
            <li class="tt-item">{{ elementData.valueRange }}</li>
            <li class="tt-item">{{ elementData.constraintInfo }}</li>
          </ul>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { getTreeData, getEleData, downloadExcel, downloadXml } from '@/api/toolsData'
  import { downloadFile } from '@/utils/index'
  export default {
    name: 'DataEle',
    data() {
      return {
        loading: false,
        currentId: null,
        treeData: [],
        elementData: {},
        filterText: ''
      }
    },
    watch: {
      filterText(val) {
        this.$refs.myTree.filter(val)
      }
    },
    created() {
      this.queryTreeData()
    },
    methods: {
      downloadFile,
      // 禁止输入空格
      keydown(e) {
        if (e.keyCode === 32) {
          e.returnValue = false
        }
      },
      // =========tree====
      queryTreeData() {
        this.loading = true
        getTreeData().then(res => {
          this.loading = false
          if (res.state === 200) {
            this.treeData = res.data
          }
        }).catch(err => {
          this.loading = false
          console.log(err)
        })
      },
      changeTree(data, node) {
        // 最底层元素才调接口
        if (data.children && data.children.length === 0) {
          this.currentId = data.sectionId
          this.queryEleData()
        }
      },
      filterNode(value, data) {
        if (!value) return true
        return data.cnName.indexOf(value) !== -1
      },
      // =========element=======
      queryEleData() {
        getEleData({ sectionId: this.currentId }).then(res => {
          if (res.state === 200) {
            if (res.data) {
              this.elementData = res.data
            } else {
              this.elementData = {}
            }
          }
        }).catch(err => {
          console.log(err)
        })
      },
      // 下载
      download(type) {
        const nodes = this.$refs.myTree.getCheckedNodes(false, true)
        if (nodes.length > 0) {
          const ids = []
          for (const item of nodes) {
            ids.push(item.sectionId)
          }
          // excel下载
          if (type === 1) {
            downloadExcel(ids).then(res => {
              downloadFile(res, '元数据', 'xls')
            }).catch(err => {
              console.log(err)
            })
          }
          // xml下载
          if (type === 2) {
            downloadXml(ids).then(res => {
              downloadFile(res, '元数据', 'xml')
            })
          }
        } else {
          this.$notify({
            title: '请先选择要下载的子集',
            type: 'warning',
            duration: 2000
          })
          return false
        }
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
    .data-element-container {
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      padding: 20px 20px 0;
      padding-top: 100px;
      .tree-container {
        width: 16%;
        
        .scrollbar-wrapper-tree {
          padding:20px 7px;
          border: 1px solid rgb(187, 187, 187);
          min-height: 600px;
          margin-top: 10px;
        }
      }
      .custom-tree-node {
        width: 85%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .item-name {
          font-size: 16px;
          .svg-icon {
            color: #0068EB;
          }
        }
        .bold-size {
          color: #000;
          font-weight: 600;
        }
        .normal-size {
          color: #333;
        }
      }
      .el-tree {
        background-color: rgba(255,255,255,0);
        >>>.el-tree-node__content {
          height: 56px;
          &:hover {
            background-color: #D4EAFF;
            box-shadow: -2px 0px 0px 0px #0065C8 inset;
          }
        }
        >>>.el-icon-caret-right {
          font-size: 18px;
        }
        >>>.el-icon-caret-right:not(.is-leaf) {
          color: rgba(0, 104, 235, .5);
        }
      }
      >>>.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
        background-color: #D4EAFF;
        box-shadow: -2px 0px 0px 0px #0065C8 inset;
      }
      .right-part {
        width: 60%;
        height: 600px;
        margin-left: 20px;
        .element-content {
          border: 1px solid rgba(207, 207, 207, .6);
          background-color: #fff;
          margin-top: 10px;
          display: grid;
          grid-template-columns: 30% 70%;
          ul {
            margin: 0;
            padding: 0;
            li {
              list-style: none;
              padding: 20px 0;
              font-size: 18px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
          .left-tr-list {
            background-color: rgba(245, 245, 245, 1);
            .tr-item {
              text-align: center;
              border-right: 1px solid rgba(207, 207, 207, .4);
              border-bottom: 1px solid rgba(207, 207, 207, .2);
            }
          }
          .right-tt-list {
            .tt-item {
              margin: 0 10px;
            }
          }
        }
      }
    }
  </style>
  