import request from '@/utils/request'

// 子集
export function getTreeData() {
    return request({
      url: window.g.toolsData + '/dataSection/findTree',
      method: 'get'
    })
  }

  // 元素
export function getEleData(id) {
    return request({
      url: window.g.toolsData + '/dataElement/findBySectionId',
      method: 'get',
      params: id
    })
  }

  // 元数据下载
export function downloadExcel(ids) {
    return request({
      url:window.g.toolsData + '/download/downloadExcel?sectionIdList=' + ids,
      method: 'get',
      responseType: 'blob'
    })
  }
  
  export function downloadXml(ids) {
    return request({
      url: window.g.toolsData + '/download/downloadListXml?sectionIdList=' + ids,
      method: 'get',
      responseType: 'blob'
    })
  }
  
  